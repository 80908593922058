import React, { useState, useEffect } from "react";

import { PageHeader, Spin, Drawer, Modal } from "antd";
import { Upload, message, Menu } from "antd";
import { InboxOutlined } from "@ant-design/icons";

import { CustomerDescription, PreviewForm } from "./components";

const axios = require("axios").default;
const { Dragger } = Upload;
const config = require("../../config/appConfig");

// set default header for all requests to API server
axios.defaults.headers.common["x-access-token"] = new URLSearchParams(
  window.location.search
).get("jwt");

const KYCHome = (props) => {
  const [customerInfo, setCustomerInfo] = useState({});
  const [customerInfoReceived, setCustomerInfoReceived] = useState(false);
  const [fileObj, setFileObj] = useState({});
  const [fileUploadPath, setFileUploadPath] = useState(null);
  const [openFileDrawer, setOpenFileDrawer] = useState(false);

  const getGender = (abbr) => {
    const gender = {
      M: "Male",
      F: "Female",
      T: "Transgender",
      O: "Not Applicable",
    };

    return gender[abbr];
  };

  function fetchCustomerInfo() {
    axios
      .get(config.APIS.getContact.replace("<PartyNumber>", props.partyNumber))
      .then(function (response) {
        if (response.data) {
          response.data.DateOfBirth = response.data.DateOfBirth
            ? response.data.DateOfBirth.split("-").reverse().join("/")
            : "";
          response.data.Gender = getGender(response.data.Gender);
          response.data.PrimaryAddress =
            response.data["Address"]["items"].find(
              (each) => each.PrimaryFlag === true
            ) || {};
          setCustomerInfo((customerInfo) => response.data);
          setCustomerInfoReceived((customerInfoReceived) => true);
        } else {
          setCustomerInfo((customerInfo) => {});
        }
      })
      .catch(function (error) {
        let title;
        let content;
        if (error.response && error.response.status === 401) {
          title = "Unauthorized!";
          content = error.response.data.message;
        } else {
          console.log({}, error);
          title = "Internal error";
          content = "Couldn't get data from API server";
        }
        Modal.error({ title: title, content: content });
      });
  }

  useEffect(() => {
    fetchCustomerInfo();
  }, []);

  const onFileChange = (info) => {
    const { status } = info.file;
    setFileObj((file) => info.file);
    console.log(info);
    if (status === "done") {
      setFileUploadPath((fileUploadPath) => info.file.response.filename);
      message.success(`${info.file.name} file uploaded for processing.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const closeFilePreview = (info) => {
    setOpenFileDrawer((openFileDrawer) => false);
  };

  const openFilePreview = (fileInfo) => {
    setOpenFileDrawer((openFileDrawer) => true);
  };

  const drawerProps = {
    title: "Validate and Save",
    placement: "bottom",
    closable: true,
    destroyOnClose: true,
    height: "80vh",
    visible: openFileDrawer,
    onClose: closeFilePreview,
  };

  const loaderProps = {
    name: "file",
    multiple: false,
    listType: "picture",
    accept: "image/*",
    showUploadList: {
      showPreviewIcon: true,
      showRemoveIcon: true,
    },
    maxCount: 1,
    action: config.APIS.fileUpload,
    headers: {
      "x-access-token": new URLSearchParams(window.location.search).get("jwt"),
    },
    onChange: onFileChange,
    onPreview: openFilePreview,
  };

  const dragger = (
    <Dragger {...loaderProps}>
      <p className="ant-upload-drag-icon" style={{ padding: "15px" }}>
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag file to this area to upload
      </p>
      <p className="ant-upload-hint" style={{ paddingBottom: "15px" }}>
        Upload your ID proofs here. Once you upload, click on the file list
        below to validate and save the details.
      </p>
    </Dragger>
  );

  let pageContent = (
    <div style={{ padding: "16px 24px" }}>
      <Spin spinning={!customerInfoReceived} delay={200}>
        <CustomerDescription info={customerInfo}></CustomerDescription>
        <Menu mode="horizontal" defaultSelectedKeys="1">
          <Menu.Item key="1">
            <b>Upload and Extract Information</b>
          </Menu.Item>
        </Menu>
        {dragger}
      </Spin>
      <Drawer {...drawerProps}>
        <PreviewForm
          fileName={fileObj.name}
          fileUploadPath={fileUploadPath}
          customerInfo={customerInfo}
          fileType={fileObj.fileType}
          closePreview={closeFilePreview}
        />
      </Drawer>
    </div>
  );

  return (
    <div>
      <PageHeader backIcon={false} title="Transcloud OCR"></PageHeader>
      {pageContent}
    </div>
  );
};

export default KYCHome;
