import React, { useState, useEffect } from "react";

import { Form, Button, Modal } from "antd";
import { Select, Input, Spin, Checkbox } from "antd";

import { CloseCircleFilled, CheckCircleFilled } from "@ant-design/icons";

const axios = require("axios").default;
const { Option } = Select;
const config = require("../../../config/appConfig");

const ocr_cx_map = {
  PAN: {
    FirstName: "first_name",
    LastName: "last_name",
    DateOfBirth: "dob",
    PersonDEO_FB_NameOfFather_c: "father_spouse_name",
    TaxpayerIdentificationNumber: "id",
  },
  "AADHAAR-FRONT": {
    FirstName: "first_name",
    LastName: "last_name",
    DateOfBirth: "dob",
    Gender: "gender",
    PersonDEO_AADHAARNumber_c: "id",
  },
  "AADHAAR-BACK": {
    Address1: "address_line1",
    Address2: "address_line2",
    Address3: "address_line3",
    City: "city",
    State: "state",
    PostalCode: "zipcode",
  },
  AADHAAR: {
    FirstName: "first_name",
    LastName: "last_name",
    DateOfBirth: "dob",
    Gender: "gender",
    PersonDEO_AADHAARNumber_c: "id",
    Address1: "address_line1",
    Address2: "address_line2",
    Address3: "address_line3",
    City: "city",
    State: "state",
    PostalCode: "zipcode",
  },
};

axios.defaults.headers.common["x-access-token"] = new URLSearchParams(
  window.location.search
).get("jwt");

const PreviewForm = (props) => {
  const [extractedInfo, setExtractedInfo] = useState(null);
  const [extractedDocType, setExtractedDocType] = useState(null);

  const formItemLayout = {
    labelCol: { xs: { span: 24 }, sm: { span: 6 } },
    wrapperCol: { xs: { span: 24 }, sm: { span: 10 } },
  };

  const textExtract = (bucketLocation) => {
    axios
      .post(config.APIS.ocrExtract, {
        name: bucketLocation,
      })
      .then(function (response) {
        let data = {};
        let d = response.data;

        if (d.doc_type in ocr_cx_map) {
          for (const each in ocr_cx_map[d.doc_type]) {
            data[each] = d.values[ocr_cx_map[d.doc_type][each]];
          }
          data["DocType"] = d.doc_type;
        } else {
          Modal.warning({
            title: "Extraction error",
            content:
              "Couldn't extract information from the document. Please upload another file.",
          });
        }
        setExtractedInfo((extractedInfo) => data);
        setExtractedDocType((extractedDocType) => d.doc_type);
      })
      .catch(function (error) {
        let title;
        let content;
        if (error.response && error.response.status === 401) {
          title = "Unauthorized!";
          content = error.response.data.message;
        } else {
          console.log({}, error);
          title = "Internal error";
          content = "Couldn't get data from API server";
        }
        Modal.error({ title: title, content: content });
      });
  };

  useEffect(() => {
    textExtract(props.fileUploadPath);
  }, []);

  const matchingIcon = (field, value1, value2) => {
    let matchIcon = <CheckCircleFilled style={{ color: "#22BC22" }} />;
    let unmatchIcon = <CloseCircleFilled style={{ color: "#FF0000" }} />;
    let isaMatch = false;

    if (value1 && value2 && value1.toLowerCase() === value2.toLowerCase()) {
      isaMatch = true;
    }
    return isaMatch ? matchIcon : unmatchIcon;
  };

  let fields = [];
  if (extractedInfo !== null && Object.keys(extractedInfo).length > 0) {
    let display_keys = {
      FirstName: "First Name",
      LastName: "Last Name",
      DateOfBirth: "Date Of Birth",
      PersonDEO_FB_NameOfFather_c: "Father Name",
      PersonDEO_AADHAARNumber_c: "Aadhaar Number",
      TaxpayerIdentificationNumber: "PAN Number",
      Address1: "Address Line 1",
      Address2: "Address Line 2",
      Address3: "Address Line 3",
      PostalCode: "Postal Code",
      State: "State",
      City: "City",
      Gender: "Gender",
    };

    let info =
      extractedDocType === "AADHAAR-BACK"
        ? props.customerInfo["PrimaryAddress"]
        : props.customerInfo;

    if (extractedDocType === "AADHAAR") {
      info = {
        ...info,
        ...props.customerInfo["PrimaryAddress"],
      };
    }

    for (const key in extractedInfo) {
      if (key === "DocType") continue;
      let icon = matchingIcon(key, info[key], extractedInfo[key]);

      fields.push(
        <Form.Item
          name={key}
          key={key}
          label={display_keys[key]}
          extra={
            <div>
              {icon} {info[key] || "<empty>"}
            </div>
          }
          rules={[
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (getFieldValue("check_" + key)) {
                  return value
                    ? Promise.resolve()
                    : Promise.reject("Please enter the value!");
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
        >
          <Input
            addonBefore={
              <Form.Item name={"check_" + key} noStyle valuePropName="checked">
                <Checkbox defaultChecked={false} />
              </Form.Item>
            }
          />
        </Form.Item>
      );
    }
  }

  const saveValues = (newValues) => {
    let submitValues = {};
    for (const key in newValues) {
      if (key.startsWith("check_") && newValues[key]) {
        let actualKey = key.replace("check_", "");
        submitValues[actualKey] = newValues[actualKey];
      }
    }

    let data_parts = [];
    if (
      newValues.DocType === "AADHAAR-BACK" ||
      newValues.DocType === "AADHAAR"
    ) {
      let part = {};

      if (props.customerInfo["PrimaryAddress"]["AddressNumber"]) {
        part = {
          id: "part2",
          operation: "update",
          path:
            "/contacts/" +
            props.customerInfo["PartyNumber"] +
            "/child/Address/" +
            props.customerInfo["PrimaryAddress"]["AddressNumber"],
          payload: {},
        };
      } else {
        part = {
          id: "part3",
          operation: "create",
          path:
            "/contacts/" +
            props.customerInfo["PartyNumber"] +
            "/child/Address/",
          payload: {},
        };

        part["payload"]["PrimaryFlag"] = true;
        part["payload"]["AddressType"] = "COMMUNICATION,PERMANENT";
        part["payload"]["Country"] = "IN";
      }

      for (const each in ocr_cx_map["AADHAAR-BACK"]) {
        if (each in submitValues) {
          part["payload"][each] = submitValues[each];
        }
      }

      data_parts.push(part);
    }

    if (newValues.DocType !== "AADHAAR-BACK") {
      let part = {
        id: "part1",
        operation: "update",
        path: "/contacts/" + props.customerInfo["PartyNumber"],
        payload: {},
      };

      if (submitValues["DateOfBirth"]) {
        submitValues["DateOfBirth"] = submitValues["DateOfBirth"]
          .match("^([0-9]{2}).([0-9]{2}).([0-9]{4})$")
          .splice(1, 3)
          .reverse()
          .join("-");
      }

      if (submitValues["Gender"]) {
        if (
          submitValues["Gender"] === "MALE" ||
          submitValues["Gender"] === "Male"
        ) {
          submitValues["Gender"] = "M";
        } else if (
          submitValues["Gender"] === "FEMALE" ||
          submitValues["Gender"] === "Female"
        ) {
          submitValues["Gender"] = "F";
        } else if (
          submitValues["Gender"] === "TRANSGENDER" ||
          submitValues["Gender"] === "Transgender"
        ) {
          submitValues["Gender"] = "T";
        }
      }

      if (newValues.DocType === "AADHAAR") {
        for (const each in ocr_cx_map["AADHAAR-FRONT"]) {
          if (each in submitValues) {
            part["payload"][each] = submitValues[each];
          }
        }
      } else {
        part["payload"] = submitValues;
      }

      data_parts.push(part);
    }

    let data = {
      parts: data_parts,
    };

    if (Object.keys(submitValues).length > 0) {
      axios
        .post(config.APIS.updateContact, data, {
          "Content-Type": "application/json",
        })
        .then(function (response) {
          Modal.info({
            title: "Success",
            content: "New values are updated successfully!",
          });
        })
        .catch(function (error) {
          let title;
          let content;
          if (error.response && error.response.status === 401) {
            title = "Unauthorized!";
            content = error.response.data.message;
          } else {
            console.log({}, error);
            title = "Server error";
            content = "Bad Request!";
          }
          Modal.error({ title: title, content: content });
        });
    } else {
      Modal.error({
        title: "Insufficient values",
        content: "Please select the values to be updated",
      });
    }
  };

  const saveFile = (value) => {
    let submitValues = {};
    submitValues.uri = props.fileUploadPath;
    submitValues.filename =
      props.customerInfo["FirstName"] +
      "_" +
      props.customerInfo["LastName"] +
      "_" +
      extractedDocType +
      "_" +
      new Date().toJSON().slice(0, 10).split("-").reverse().join("") +
      "." +
      props.fileName.split(".").slice(-1).pop();

    let api = config.APIS.attachment.replace(
      "<PartyNumber>",
      props.customerInfo["PartyNumber"]
    );
    axios
      .post(api, submitValues, {
        "Content-Type": "application/json",
      })
      .then(function (response) {
        Modal.info({
          title: "Success",
          content: "File is uploaded successfully to the CRM!",
        });
      })
      .catch(function (error) {
        let title;
        let content;
        if (error.response && error.response.status === 401) {
          title = "Unauthorized!";
          content = error.response.data.message;
        } else {
          console.log({}, error);
          title = "Internal error";
          content = "Couldn't get data from API server";
        }
        Modal.error({ title: title, content: content });
      });
  };

  let previewForm;
  if (extractedInfo === null) {
    previewForm = (
      <Spin spinning={extractedInfo === null ? true : false}>
        <div />
      </Spin>
    );
  } else {
    previewForm = (
      <Spin spinning={extractedInfo === null ? true : false}>
        <Form
          name="new-values"
          initialValues={extractedInfo}
          onFinish={saveValues}
          {...formItemLayout}
        >
          <Form.Item label="File Name">{props.fileName}</Form.Item>
          <Form.Item label="Document Type" name="DocType">
            <Select disabled>
              <Option value="PAN">PAN card</Option>
              <Option value="AADHAAR-FRONT">Aadhaar card(Frontside)</Option>
              <Option value="AADHAAR-BACK">Aadhaar card(Backside)</Option>
              <Option value="AADHAAR">Aadhaar card</Option>
            </Select>
          </Form.Item>
          {fields}
          <Form.Item label=" " colon={false}>
            <Button
              type="primary"
              htmlType="submit"
              // loading={this.state.newValueSubmitted}
            >
              Save New Values
            </Button>
            &nbsp;
            <Button
              type="primary"
              onClick={saveFile}
              // loading={this.state.newDocSubmitted}
            >
              Save File
            </Button>
            &nbsp;
            <Button type="primary" onClick={props.closePreview}>
              Close
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    );
  }

  return previewForm;
};
export default PreviewForm;
