import "./App.css";
import KYCHome from "./pages/KYCHome";

import { Modal } from "antd";
import React from "react";

function App() {
  const party_number = new URLSearchParams(window.location.search).get(
    "PartyNumber"
  );
  const jwt = new URLSearchParams(window.location.search).get("jwt");

  let page = <div />;

  if (!jwt) {
    Modal.error({
      title: "Unauthorized!",
      content: "Please check with your administrator",
    });
  } else if (!party_number) {
    Modal.error({
      title: "Integration error",
      content: "Search key not provided",
    });
  } else {
    page = <KYCHome partyNumber={party_number} />;
  }

  return <div className="App">{page}</div>;
}

export default App;
