import React from "react";

import { Row, Col, List } from "antd";

const config = require("../../../config/appConfig");

const CustomerDescription = (props) => {
  let info = props.info;
  info["MobileCountryCode"] = info["MobileCountryCode"] || "";
  info["MobileNumber"] = info["MobileNumber"] || "";

  let address = info["PrimaryAddress"] || { AddressType: "" };
  let country = address["Country"]
    ? config.country_codes[address["Country"]]
    : "";

  let address_type = address["AddressType"]
    ? address["AddressType"]
        .split(",")
        .map((str) => str.charAt(0) + str.substr(1).toLowerCase())
        .join(", ")
    : "";

  const customer_info = [
    {
      "First Name": info["FirstName"],
      "Last Name": info["LastName"],
      "Middle Name": info["MiddleName"],
      "Father Name": info["PersonDEO_FB_NameOfFather_c"],
      Gender: info["Gender"],
      "Date of Birth": info["DateOfBirth"],
    },
    {
      "Party Number": info["PartyNumber"],
      "Aadhaar Number": info["PersonDEO_AADHAARNumber_c"]
        ? "xxxx xxxx " + info["PersonDEO_AADHAARNumber_c"].slice(8)
        : "",
      "PAN Number": info["TaxpayerIdentificationNumber"],
      "Mobile Number":
        "+" + info["MobileCountryCode"] + " " + info["MobileNumber"],
    },
    {
      "Primary Address Type": address_type,
      "Address Line 1": address["Address1"],
      "Address Line 2": address["Address2"],
      "Address Line 3": address["Address3"],
      City: address["City"],
      "Postal Code": address["PostalCode"],
      State: address["State"],
      Country: country,
    },
  ];

  const descriptionComponent = (
    <Row>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <List
          dataSource={Object.entries(customer_info[0])}
          renderItem={(item) => (
            <List.Item>
              <span className="desc-column">{item[0]}:</span> {item[1]}
            </List.Item>
          )}
        />
      </Col>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <List
          dataSource={Object.entries(customer_info[1])}
          renderItem={(item) => (
            <List.Item>
              <span className="desc-column">{item[0]}:</span> {item[1]}
            </List.Item>
          )}
        />
      </Col>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <List
          dataSource={Object.entries(customer_info[2])}
          renderItem={(item) => (
            <List.Item>
              <span className="desc-column">{item[0]}:</span> {item[1]}
            </List.Item>
          )}
        />
      </Col>
    </Row>
  );

  return descriptionComponent;
};
export default CustomerDescription;
